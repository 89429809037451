export const DEV = "dev";
export const QA = "qa";
export const PROD = "prod";

export const getIsLocalEnv = ({ hostname } = window.location) => {
	return hostname === "localhost";
};

export const getEnvFromHostname = () => {
	let { hostname } = window.location;
	let env = PROD;

	const HOSTNAMES_DEV = [
		"d3quv0ne8qn9dc.cloudfront.net",
		"dev.aessuccess.org",
		"www.dev.aessuccess.org",
		"localhost"
	];
	const HOSTNAMES_QA = [
		"d19tq170auvy7.cloudfront.net",
		"qa.aessuccess.org",
		"www.qa.aessuccess.org"
	];

	if (HOSTNAMES_DEV.includes(hostname)) {
		env = DEV;
		return env.toLowerCase();
	} else if (HOSTNAMES_QA.includes(hostname)) {
		env = QA;
		return env.toLowerCase();
	}

	return env.toLowerCase();
};

export const getEnvConfigFileName = () => {
	let fileName = "configuration.json";

	if (isLocalEnv) {
		fileName = "configuration.local.json";
	} else if (currentEnv === DEV) {
		fileName = "configuration.dev.json";
	} else if (currentEnv === QA) {
		fileName = "configuration.qa.json";
	}

	return fileName;
};

export const currentEnv = getEnvFromHostname();
export const isLocalEnv = getIsLocalEnv();