import * as Buttons from "./ThemeComponents/buttons";
import * as Cards from "./ThemeComponents/cards";
import * as Headings from "./ThemeComponents/headings";
import { GetTheme as GeneralGetTheme } from "@pheaa/public-websites";
import { createTheme } from "@mui/material/styles";
import { isObject } from "@pheaa/public-websites/dist/utils/type-checks/object";
import theme from "./../styles/theme.scss";

const
	merge = require("deepmerge"),
	GetTheme = customTheme => {
		const
			primaryButton = merge({}, Buttons.primaryButton()),
			secondaryButton = merge({}, Buttons.secondaryButton()),
			outlineButton = merge({}, Buttons.outlineButton()),
			tertiaryButton = merge({}, Buttons.tertiaryButton()),
			ctalink = merge({}, Buttons.ctalink()),
			ctalinkWithArrow = merge({}, Buttons.ctalinkWithArrow()),
			rteCardWithBorder = merge({}, Cards.rteCardWithBorder()),
			defaultTheme = {
				identifier: "aesTheme",
				breakpoints: {
					// Assumes all measurements are in pixels
					values: {
						xs: parseInt(theme.breakpoint_xs, 10),
						sm: parseInt(theme.breakpoint_sm, 10),
						md: parseInt(theme.breakpoint_md, 10),
						lg: parseInt(theme.breakpoint_lg, 10),
						xl: parseInt(theme.breakpoint_xl, 10)
					}
				},
				components: {
					MuiButton: {
						defaultProps: {
							disableRipple: true
						},
						variants: [
							{
								props: { variant: "primary" },
								style: { ...primaryButton }
							},
							{
								props: { variant: "secondary" },
								style: { ...secondaryButton }
							},
							{
								props: { variant: "outline" },
								style: { ...outlineButton }
							},
							{
								props: { variant: "tertiary" },
								style: { ...tertiaryButton }
							},
							{
								props: { variant: "ctalink" },
								style: { ...ctalink }
							},
							{
								props: { variant: "ctalinkWithArrow" },
								style: { ...ctalinkWithArrow }
							}
						]
					},
					MuiCard: {
						variants: [
							{
								props: { variant: "rteCardWithBorder" },
								style: { ...rteCardWithBorder }
							}

						]
					}
				},
				headings: {
					InteriorPageHeading: {
						...merge({}, Headings.InteriorPageHeading().base),
						".Heading__caption--isInteriorPageHeading": merge({}, Headings.InteriorPageHeading().caption),
						".Heading__content--isInteriorPageHeading": merge({}, Headings.InteriorPageHeading().content)
					},
					InteriorPageHeadingCentered: {
						...merge({}, Headings.InteriorPageHeadingCentered().base),
						".Heading__caption--isInteriorPageHeadingCentered": merge({}, Headings.InteriorPageHeadingCentered().caption),
						".Heading__content--isInteriorPageHeadingCentered": merge({}, Headings.InteriorPageHeadingCentered().content)
					},
					InteriorPageSubHeading: {
						...merge({}, Headings.InteriorPageSubHeading().base),
						".Heading__caption--isInteriorPageSubHeading": merge({}, Headings.InteriorPageSubHeading().caption),
						".Heading__content--isInteriorPageSubHeading": merge({}, Headings.InteriorPageSubHeading().content)
					},
					CardHeading: {
						...merge({}, Headings.CardHeading().base),
						".Heading__caption--isCardHeading": merge({}, Headings.CardHeading().caption),
						".Heading__content--isCardHeading": merge({}, Headings.CardHeading().content)
					},
					RTEHeading: {
						...merge({}, Headings.RTEHeading().base),
						".Heading__caption--isRTEHeading": merge({}, Headings.RTEHeading().caption),
						".Heading__content--isRTEHeading": merge({}, Headings.RTEHeading().content)
					},
					RTESubHeading: {
						...merge({}, Headings.RTESubHeading().base),
						".Heading__caption--isRTESubHeading": merge({}, Headings.RTESubHeading().caption),
						".Heading__content--isRTESubHeading": merge({}, Headings.RTESubHeading().content)
					}
				},
				palette: {
					primary: {
						main: theme.color_primary
					},
					secondary: {
						main: theme.color_secondary
					},
					error: {
						main: theme.color_error
					},
					text: {
						primary: theme.color_text_primary,
						secondary: theme.color_text_secondary
					},
					background: {
						ad: theme.color_ad_background
					},
					contrastThreshold: 4.5
				},
				mixins: {
					focus: {
						boxShadow: theme.focus_shadow,
						outline: theme.focus_outline,
						outlineOffset: theme.focus_outline_offset
					},
					headings: {
						InteriorPageHeading: {
							base: merge({}, Headings.InteriorPageHeading().base),
							caption: merge({}, Headings.InteriorPageHeading().caption),
							content: merge({}, Headings.InteriorPageHeading().content)
						},
						InteriorPageSubHeading: {
							base: merge({}, Headings.InteriorPageSubHeading().base),
							caption: merge({}, Headings.InteriorPageSubHeading().caption),
							content: merge({}, Headings.InteriorPageSubHeading().content)
						},
						CardHeading: {
							base: merge({}, Headings.CardHeading().base),
							caption: merge({}, Headings.CardHeading().caption),
							content: merge({}, Headings.CardHeading().content)
						},
						RTEHeading: {
							base: merge({}, Headings.RTEHeading().base),
							caption: merge({}, Headings.RTEHeading().caption),
							content: merge({}, Headings.RTEHeading().content)
						},
						RTESubHeading: {
							base: merge({}, Headings.RTESubHeading().base),
							caption: merge({}, Headings.RTESubHeading().caption),
							content: merge({}, Headings.RTESubHeading().content)
						}
					},
					hiddenVisually: {
						left: "-9999px !important",
						overflow: "hidden !important",
						position: "absolute !important",
						top: "-9999px !important"
					},
					hiddenVisuallyUndo: {
						left: "0 !important",
						overflow: "visible !important",
						position: "static !important",
						top: "0 !important"
					},
					navigation: {
						desktop: {
							primaryLinkColor: theme.navigation_primary_link_color,
							primaryLinkColorHover: theme.navigation_primary_link_color_hover,
							primaryBorderColor: theme.navigation_primary_border_color,
							primaryBorderColorHover: theme.navigation_primary_border_color_hover,
							secondaryLinkColor: theme.navigation_secondary_link_color,
							secondaryLinkColorHover: theme.navigation_secondary_link_color_hover,
							tertiaryLinkColor: theme.navigation_tertiary_link_color,
							tertiaryLinkColorHover: theme.navigation_tertiary_link_color_hover,
							signInLinkColor: theme.navigation_sign_in_link_color,
							signInLinkColorHover: theme.navigation_sign_in_link_color_hover,
							signInLinkBackgroundColor: theme.navigation_sign_in_link_background_color,
							signInLinkBackgroundColorHover: theme.navigation_sign_in_link_background_color_hover,
							signInButtonColor: theme.navigation_sign_in_button_color,
							signInButtonColorHover: theme.navigation_sign_in_button_color_hover,
							signInButtonBackgroundColor: theme.navigation_sign_in_button_background_color,
							signInButtonBackgroundColorHover: theme.navigation_sign_in_button_background_color_hover
						},
						mobile: {
							linkColor: theme.navigation_mobile_link_color,
							linkColorHover: theme.navigation_mobile_link_color_hover,
							backButtonColor: theme.navigation_mobile_back_button_color,
							backButtonColorHover: theme.navigation_mobile_back_button_color_hover,
							backButtonBackgroundColor: theme.navigation_mobile_back_button_background_color,
							backButtonBackgroundColorHover: theme.navigation_mobile_back_button_background_color_hover,
							signInLinkColor: theme.navigation_mobile_sign_in_link_color,
							signInLinkColorHover: theme.navigation_mobile_sign_in_link_color_hover,
							signInLinkBackgroundColor: theme.navigation_mobile_sign_in_link_background_color,
							signInLinkBackgroundColorHover: theme.navigation_mobile_sign_in_link_background_color_hover,
							headingUnderline: theme.navigation_mobile_heading_underline,
							drawerPaperBackgroundColor: theme.navigation_mobile_drawer_paper_background_color,
							drawerModalBackgroundColor: theme.navigation_mobile_drawer_modal_background_color,
							drawerButtonBackgroundColorHover: theme.navigation_mobile_drawer_button_background_color_active,
							drawerPaperColor: theme.navigation_mobile_drawer_paper_color,
							dividerColor: theme.navigation_mobile_divider_color,
							closeIconColor: theme.navigation_mobile_close_icon_color,
							buttonIconColor: theme.navigation_mobile_button_icon_color,
							logoBackgroundColor: theme.navigation_mobile_logo_background_color
						}
					},
					overlay: {
						iconCard: theme.icon_card_background_hover_overlay
					},
					variantButtons: {
						primary: { ...primaryButton },
						secondary: { ...secondaryButton },
						outline: { ...outlineButton },
						tertiary: { ...tertiaryButton },
						ctalink: { ...ctalink },
						ctalinkWithArrow: { ...ctalinkWithArrow }
					},
					variantCards: {
						border: { ...rteCardWithBorder }
					}
				},
				shape: {
					// Assumes style in "rem" and HTML font size = 10px
					borderRadius: parseFloat(theme.shape_border_radius) * 10
				},
				breadcrumb: {
					backgroundColor: theme.breadcrumb_background_color,
					color: theme.breadcrumb_color,
					colorHover: theme.breadcrumb_color_hover,
					fontFamily: {
						default: theme.breadcrumb_font_family_default,
						last: theme.breadcrumb_font_family_last
					},
					fontSize: theme.breadcrumb_font_size
				},
				search: {
					inputBorderColor: theme.search_input_border_color,
					inputPlaceholderColor: theme.search_input_placeholder_color,
					closeButtonColor: theme.search_close_button_color,
					backgroundGradients: theme.search_background_gradients
				},
				skipLinks: {
					backgroundColor: theme.skipLinks_background_color,
					border: theme.skipLinks_border,
					fontFamily: theme.skipLinks_font_family,
					fontSize: theme.skipLinks_font_size
				},
				typography: {
					fontFamily: theme.fontPrimary,
					// Assumes style in "rem" and HTML font size = 10px
					fontSize: parseFloat(theme.font_size) * 10,
					// Assumes value is a percentage
					htmlFontSize: 16 * (parseFloat(theme.html_font_size) / 100),
					body1: {
						fontFamily: theme.fontPrimary,
						fontSize: theme.font_size,
						lineHeight: theme.line_height,
						hyphens: theme.hyphens
					}
				},
				// Custom object to pass font families into theme object
				fontFamilies: {
					bold: theme.fontPrimaryBold,
					default: theme.fontPrimary,
					extraBold: theme.fontPrimaryExtrabold,
					light: theme.fontPrimaryLight,
					robotoSlab: theme.fontRobotoSlab,
					robotoSlabBold: theme.fontRobotoSlabBold,
					semiBold: theme.fontPrimarySemibold
				},
				minWidth: theme.min_width,
				contentSpacing: {
					mobileHeader: theme.content_header_spacing_mobile,
					desktopHeader: theme.content_header_spacing_desktop,
					mobileInterior: theme.content_interior_spacing_mobile,
					desktopInterior: theme.content_interior_spacing_desktop,
					mobileFooter: theme.content_footer_spacing_mobile,
					desktopFooter: theme.content_footer_spacing_desktop
				},
				bannerHeaderHeights: {
					landing: theme.banner_header_landing_min_height,
					subLanding: theme.banner_header_subLanding_min_height
				},
				tabs: {
					desktop: {
						list: {
							borderBottomColor: theme.tabs_list_border_bottom_color,
							borderRadius: theme.tabs_list_border_radius,
							borderBottomStyle: theme.tabs_list_border_bottom_style,
							borderBottomWidth: theme.tabs_list_border_bottom_width,
							paddingBottom: theme.tabs_list_padding_bottom,
							paddingLeft: theme.tabs_list_padding_left,
							paddingRight: theme.tabs_list_padding_right,
							paddingTop: theme.tabs_list_padding_top
						},
						indicator: {
							display: theme.tabs_mui_indicator_display
						},
						panel: {
							paddingBottom: theme.tabs_panel_padding_bottom,
							paddingLeft: theme.tabs_panel_padding_left,
							paddingRight: theme.tabs_panel_padding_right,
							paddingTop: theme.tabs_panel_padding_top,
							firstHeading: {
								color: theme.tabs_panel_heading_first_color,
								fontFamily: theme.tabs_panel_heading_first_font_family,
								fontSize: theme.tabs_panel_heading_first_font_size
							}
						},
						scrollButtons: {
							width: theme.tabs_scroll_buttons_width_desktop
						},
						tab: {
							backgroundColor: theme.tabs_tab_background_color,
							borderBottomLeftRadius: theme.tabs_tab_border_bottom_left_radius,
							borderBottomRightRadius: theme.tabs_tab_border_bottom_right_radius,
							borderColor: theme.tabs_tab_border_color,
							borderStyle: theme.tabs_tab_border_style,
							borderTopLeftRadius: theme.tabs_tab_border_top_left_radius,
							borderTopRightRadius: theme.tabs_tab_border_top_right_radius,
							borderWidth: theme.tabs_tab_border_width,
							color: theme.tabs_tab_color,
							fontFamily: theme.tabs_tab_font_family,
							fontSize: theme.tabs_tab_font_size,
							marginBottom: theme.tabs_tab_margin_bottom,
							marginLeft: theme.tabs_tab_margin_left,
							marginLeftSubsequent: theme.tabs_tab_margin_left_subsequent,
							marginRight: theme.tabs_tab_margin_right,
							marginTop: theme.tabs_tab_margin_top,
							paddingBottom: theme.tabs_tab_padding_bottom,
							paddingLeft: theme.tabs_tab_padding_left,
							paddingRight: theme.tabs_tab_padding_right,
							paddingTop: theme.tabs_tab_padding_top,
							textTransform: theme.tabs_tab_text_transform,
							selected: {
								backgroundColor: theme.tabs_tab_background_color_selected,
								borderBottomColor: theme.tabs_tab_border_bottom_color_selected,
								borderBottomStyle: theme.tabs_tab_border_bottom_style_selected,
								borderBottomWidth: theme.tabs_tab_border_bottom_width_selected,
								borderRadius: theme.tabs_tab_border_radius_selected,
								fontFamily: theme.tabs_tab_font_family_selected,
								textTransform: theme.tabs_tab_text_transform_selected,
								after: {
									backgroundColor: theme.tabs_tab_background_color_selected_after,
									bottom: theme.tabs_tab_bottom_selected_after,
									left: theme.tabs_tab_left_selected_after,
									height: theme.tabs_tab_height_selected_after,
									width: theme.tabs_tab_width_selected_after
								}
							}
						}
					},
					tablet: {
						list: {
							paddingLeft: theme.tabs_list_padding_left_tablet,
							paddingRight: theme.tabs_list_padding_right_tablet
						},
						scrollButtons: {
							width: theme.tabs_scroll_buttons_width_tablet
						},
						tab: {
							marginLeftSubsequent: theme.tabs_tab_margin_left_subsequent_tablet
						},
						panel: {
							paddingBottom: theme.tabs_panel_padding_bottom_tablet,
							paddingLeft: theme.tabs_panel_padding_left_tablet,
							paddingRight: theme.tabs_panel_padding_right_tablet,
							paddingTop: theme.tabs_panel_padding_top_tablet
						}
					},
					mobile: {
						list: {
							paddingLeft: theme.tabs_list_padding_left_mobile,
							paddingRight: theme.tabs_list_padding_right_mobile
						},
						scrollButtons: {
							width: theme.tabs_scroll_buttons_width_mobile
						},
						tab: {
							marginLeftSubsequent: theme.tabs_tab_margin_left_subsequent_mobile
						},
						panel: {
							paddingBottom: theme.tabs_panel_padding_bottom_mobile,
							paddingLeft: theme.tabs_panel_padding_left_mobile,
							paddingRight: theme.tabs_panel_padding_right_mobile,
							paddingTop: theme.tabs_panel_padding_top_mobile
						}
					}
				},
				zIndex: {
					navForeground: theme.z_index_navigation_foreground,
					navBackground: theme.z_index_navigation_background
				}
			},
			composite = isObject(customTheme) ?
				merge.all([GeneralGetTheme(), defaultTheme, customTheme]) :
				merge.all([GeneralGetTheme(), defaultTheme]);

		return createTheme(composite);
	};


export { GetTheme };